.clientLoader {
  background-color: #0e0e0eb3;
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}
.documentlistname{
  font-size: 20px;
}
.hoverable {
  font-weight: bold;
  font-family: sans-serif;
  cursor: pointer;
  color: grey;
}
.hoverable :hover {
  color: #73b5f3;
}
.plusicon{
  position: absolute;
     right: 60px;

 }
 .notesmodal{
  width:630px
 }
@media screen and (max-width: 1200px) {
  .clientLoader {
    height: 70vh;
  }
}

@media screen and (max-width: 768px) {
  .clientLoader {
    height: 90vh;
  }
  .documentlistname{
    font-size: 16px;
  }
}
@media screen and (max-width: 568px) {
  .clientLoader {
    height: 100vh;
  }
}

.textAlignment {
  margin-right: 2rem;
  fill: #000000;
  font-size: 1.3rem;
}

.clientListItem {
  cursor: pointer;
}
.subMenuList .ant-list-bordered {
  border: none !important;
  border-radius: 0px !important;
}

.clientListItem:hover {
  background-color: rgba(42, 82, 196, 0.1);
  /* color: #fff !important; */
}

.clientListItem:active {
  background-color: rgba(5, 59, 209, 0.1);
}

.caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caretDown::before {
  -ms-transform: rotate(90deg); /* IE 9 */
  transform: rotate(90deg);
}
.nested {
  display: none;
}

.active {
  display: block;
}

.userlisting {
  border: 0;
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
}
.userlisting .clientListItem {
  border: 1px solid #e9e2e2;
  margin-bottom: 2px;
}

.userlisting .clientListItem.active {
  background-color: rgba(42, 82, 196, 0.1);
}

.userlisting .clientListItem h4 {
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: 11px;
  font-size: 18px;
  color: #444444;
}

.useravtar {
  background-color: #000;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.clientdetails {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.clientdetails .clientheading {
  font-size: 1.613rem;
  color: #444444;
}
.btn-primary {
  height: auto !important;
  padding: 4px 10px !important;
  font-size: 14px !important;
  background: #0077d8 !important;
  border-color: #0077d8 !important;
}

.iconsbox {
  width: 100%;
  /* float: left; */
  display: flex;
  /* margin-bottom: 5px; */
}

.iconsclient {
  width: 35px;
  float: left;
}

.userfeild {
  width: 100%;

  /* float: left; */
  font-size: 18px;
  /* text-transform: capitalize; */
}
.addressField {
  width: 100%;
  font-size: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.useremailfeild {
  width: 87%;
  /* float: left; */
  font-size: 18px;
  margin-left: 20px;
  text-align: left;
  /* text-transform: capitalize; */
}
.usernoemailfeild {
  width: 87%;
  font-size: 18px;
  margin-left: 17px;
  text-align: left;
  color: red;
}
.useremailfeild:hover {
  color: #1890ff !important;
  cursor: pointer;
}
.userfeild:hover {
  color: #1890ff !important;
  cursor: pointer;
}

.addressField:hover {
  color: #1890ff !important;
  cursor: pointer;
}

.profilephoto {
  width: 100%;
  text-align: center;
  padding-top: 25px;
  /* border: 2px solid red ; */
}

.profilephoto .profileimg {
  border-radius: 50%;
  margin: 0;
  margin-bottom: 10px;
  /* margin-left: 25; */
  width: 67px;
  height: 67px;
  object-fit: cover;
  /* margin-right: 57px; */
}

.userevents {
  color: #1968ff;
  text-decoration: underline;
}

.tab-title img {
  width: 31px;
}

.demo-loadmore-list {
  color: #143b7c;
}

.demoloadlisting {
  color: #143b7c;
}

.dotAlignment {
  border: 1px solid black;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50px;
  margin-right: 10px;
}

.txnhead h4 {
  text-transform: upperCase;
}

.actionbtn span {
  font-size: 16px;
}

.editdetails {
  width: 100%;
  float: left;
  text-align: right;
}

.icoplus {
  margin-top: 8px;
  display: inline-block;
  cursor: pointer;
}

.offertaskpop .modal-body {
  padding: 20px;
}

ul.propertyinfos {
  margin: 0;
  padding: 0;
  /* background-color: #f5f5f5; */
  padding: 15px;
  border-radius: 4px;
}

ul.propertyinfos select {
  height: 34px;
  line-height: 34px;
  width: 100%;
  border: 1px solid #dbdada;
}

ul.propertyinfos span {
  font-weight: 600;
  font-size: 18px;
  margin-left: 40px;
}

ul.propertyinfos li {
  font-size: 18px;
  font-weight: 600;
}

.proptyinfdropdwn {
  display: flex;
  margin-bottom: 15px;
}
.documenDetails {
  width: 100%;
  float: left;
}

.submitml1 {
  margin-left: 10px !important;
}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

.form-control:focus-within {
  color: var(--form-control-color);
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: #1890ff;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #1890ff;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: inline-grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #1890ff;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: max(2px, 0.15em) solid #1890ff;
  outline-offset: max(2px, 0.15em);
}

.ctm-radiobtn {
  display: flex;
}

span.mrgnlft {
  margin-left: 5px !important;
}

.itemdelte {
  position: absolute;
  top: 0;
  right: 0;
}

.photodelt {
  /* width: 18px;
  height: 18px; */
  cursor: pointer;
  color: rgb(223 8 28);
  fill: rgb(223 8 28);
  /* position: absolute;
  top: 0px;
  right: 100px; */
}

.phototile {
  text-transform: Capitalize;
  font-size: 16px;
  font-weight: 500;
  width: 102px;
  word-break: break-all;
}

.radiodropd {
  margin-block-end: 20px;
}

.topmenu {
  font-size: 10px;
}

label {
  font-size: 16px;
}

.ant-list-items {
  background-color: black !important;
}

.ant-collapse-header .headerText {
  color: #000 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: hsla(209, 41%, 70%, 1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsla(208, 90%, 30%, 1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(8, 81, 145, 0.692);
}

/* Adding media query for small Screen*/

.clientSectionContainer {
  padding: 1rem 0rem 0rem 0rem;
  border-radius: 8px;
  height: calc(100vh - 7rem);
/* background-color: #0077d8; */
  transition: 0.3s;
  display: flex;
  gap: 5px;
  width: "100%";

}

@media only screen and (max-device-width: 480px) {
  .notesmodal{
    width:420px
   }
   .clientSectionContainer {
    padding: 1rem 0rem 0rem 0rem;
    border-radius: 8px;
    height: calc(100vh - 9rem);
  /* background-color: #0077d8; */
    transition: 0.3s;
    display: flex;
    gap: 5px;
    width: "100%";
  
  }
  .documentlistname{
    font-size: 12px;
  }
  .plusicon{
   
     position: absolute;
      right: 10px;
    
  }
  .allClientsList {
    color: red;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: red;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: calc(100vh - 195px);
    padding: 0.5rem;
    margin-bottom: 25px;
  }
}
allClientlistList {
  overflow-y: "auto";
  overflow-x: "hidden";
  height: calc(100vh - 310px);
}
allClientlistListWithoutInput {
  overflow-y: "auto";
  overflow-x: "hidden";
  height: calc(100vh - 260px);
}
allClientlistList {
  overflow-y: "auto";
  overflow-x: "hidden";
  height: calc(100vh - 310px);
}

.dateContainer {
  font-weight: bold;
}

.deleteIcon {
  font-size: 20px;
 padding-top: -5px;
  cursor: pointer;
}

.documentTable {
  /* border-collapse: collapse; */
  width: 100%;
 margin-left: 15px;
 color: grey;
}

.documentTable td {
  padding: 2px;
  width: 5px;
  border:none;
  vertical-align: center;
}
.documentTable tr:hover {
  color: black;
  background-color: #92c9f5;
}


